//
//
//
//
//
//
//
//

export default {
    name: 'QuarterlyKeyFigures1',
    data() {
        return {
            chart: null
        }
    },
    methods: {
        changeChartType() {
            var self = this;
            var series = [];
            self.chart.series.forEach(function (s) {
                series.push(s);
            });

            series.forEach(function (s) {
                s.chart.addSeries({
                    type: 'line',
                    name: s.name,
                    color: s.color,
                    data: s.options.data
                }, false);

                s.remove();
            });
        },

        changeChart() {
            var self = this;
            self.chart.setTitle({ text: 'this is my title' });

            var newSer = {
                name: 'Malek',
                data: [4, 8, 12]
            };

            self.chart.series[0].name = newSer.name;
            self.chart.series[0].setData(newSer.data);
            self.chart.legend.allItems[0].update({ name: newSer.name });
        },

        DrawChart() {
            var self = this;
            self.chart = Highcharts.chart('container', {
                chart: {
                    type: 'bar',
                    events: {
                        //redraw: function () { alert('Redrawing....')}
                    }
                },
                title: {
                    text: 'Fruit Consumption'
                },
                xAxis: {
                    categories: ['Apples', 'Bananas', 'Oranges']
                },
                yAxis: {
                    title: {
                        text: 'Fruit eaten'
                    }
                },
                series: [{
                    name: 'Jane',
                    data: [1, 0, 4]
                }, {
                    name: 'John',
                    data: [5, 7, 3]
                }]
            });

        }
    },
    mounted() {
        let self = this;
        self.DrawChart();
    },
    watch: {
        $route() {
            
        }            
    },
    updated() {
      
    }
}
